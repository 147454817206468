import { memo, useMemo, useRef, useState } from "react";
import cls from './BackCard.module.scss';

import advancedBgImage from '@img/player-cards/advanced/advanced-bg.png';
import uniqueBgImage from '@img/player-cards/unique/unique-bg.png';
import legendaryBgImage from '@img/player-cards/legendary/legendary-bg.png';
import rareBgImage from '@img/player-cards/rare/red-bg.png';
import commonBgImage from '@img/player-cards/common/common-bg.png';

import BackHandIcon from '@img/player-cards/rank-icons/backhand.svg';
import ForehandIcon from '@img/player-cards/rank-icons/forehand.svg';
import ReceptionIcon from '@img/player-cards/rank-icons/reception.svg';
import ServeIcon from '@img/player-cards/rank-icons/serve.svg';
import SpiritIcon from '@img/player-cards/rank-icons/spirit.svg';
import StaminaIcon from '@img/player-cards/rank-icons/stamina.svg';
import VolleyIcon from '@img/player-cards/rank-icons/volley.svg';
import LinearProgress from "@components/LinearProgress";
import classNames from "classnames";

const mockCard =  {
      "id": 3168,
      "nft": {
          "name": "Tennis 34",
          "rarity": "COMMON",
          "image": {
              "image": "https://cdn.discordapp.com/attachments/1128947060159287346/1152214491262615552/image.png"
          },
          "rating": 96,
          "player_country": "Россия",
          "player_age": 34
      },
      "circulation_number": 9002,
      "chr_stamina": 52,
      "chr_volley": 84,
      "chr_reception": 16,
      "chr_serve": 77,
      "chr_backhand": 42,
      "chr_forehand": 96,
      "chr_spirit": 0,
      "chr_talent": 3,
      "chr_fatigue": 0,
      "chr_physical_form": 100,
      "items": {},
      "crew": null,
      "trauma": null,
      "player_type": "ПАРНАЯ ЖЕНСКАЯ",
      "translated_type": "Парная женская",
      "on_team": false,
      "on_sale": true
  }

const getStarsBadgeColor = (rarity: string) => {
  switch(rarity) {
    case "ADVANCED":
      return 'linear-gradient(90.00deg, rgb(68, 129, 235),rgb(4, 190, 254) 100%)'
    case "UNIQUE":
      return 'linear-gradient(60.78deg, rgb(44, 213, 101) -15.211%,rgb(22, 168, 80) 117.255%)'
    case "LEGENDARY":
      return 'linear-gradient(90.00deg, rgb(135, 77, 162),rgb(196, 58, 48) 100%)'
    case "RARE": 
      return 'linear-gradient(90.00deg, rgb(230, 24, 28),rgb(164, 0, 0) 47.917%,rgb(255, 0, 0) 100%)'
    case "COMMON":
      return 'linear-gradient(90.00deg, rgb(40, 40, 40),rgb(0, 0, 0) 100%)'
  }
}

const getTheme = (rarity: string) => {
  switch(rarity) {
    case "ADVANCED":
      return advancedBgImage
    case "UNIQUE":
      return uniqueBgImage
    case "LEGENDARY":
      return legendaryBgImage
    case "RARE": 
      return rareBgImage
    case "COMMON":
      return commonBgImage
  }
}

const getProgressBarColor = (rarity: string) => {
  switch(rarity) {
    case "ADVANCED":
      return 'linear-gradient(90.00deg, rgb(86, 108, 255),rgb(159, 162, 255) 100%)'
    case "UNIQUE":
      return 'linear-gradient(90.00deg, rgb(212, 252, 121),rgb(150, 230, 161) 100%)'
    case "LEGENDARY":
      return 'linear-gradient(90.00deg, rgb(246, 211, 101),rgb(253, 160, 133) 100%)'
    case "RARE": 
      return 'linear-gradient(90.00deg, rgb(255, 113, 113),rgb(255, 111, 111) 100%)'
    case "COMMON":
      return 'linear-gradient(90.00deg, rgb(228, 231, 255),rgb(235, 235, 235) 100%)'
  }
}

export const BackCard = memo(({card}: any) => {
  const { 
    nft: {
      rarity,
      rating
    },
    circulation_number: circulationNumber,
    chr_forehand,
    chr_serve,
    chr_stamina,
    chr_reception,
    chr_volley,
    chr_backhand,
    chr_spirit,
    chr_fatigue
  } = card;

 
  const theme = useMemo(() => getTheme(rarity), [rarity]);
  const color = {
    [cls.blackColor]: rarity === 'COMMON' || rarity === 'LEGENDARY'
  }
  const progressColor = useMemo(() => getProgressBarColor(rarity))

  return (
    <div
      className={classNames(cls.card, color)}
    >
      <img className={cls.bgImage} src={theme} alt="" />
      <div className={cls.content}>
        <div className={cls.header}>
          <div className={cls.stats}>
            <span>
              #{circulationNumber}
            </span>
          </div>
          <div className={cls.stars} style={{background: getStarsBadgeColor(rarity)}}>
            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 6.25L4.5 5.10625L6 6.25L5.4375 4.39375L6.9375 3.325H5.1L4.5 1.375L3.9 3.325H2.0625L3.5625 4.39375L3 6.25ZM4.5 7.75C3.98125 7.75 3.49375 7.65156 3.0375 7.45469C2.58125 7.25781 2.18437 6.99062 1.84687 6.65312C1.50937 6.31562 1.24219 5.91875 1.04531 5.4625C0.848437 5.00625 0.75 4.51875 0.75 4C0.75 3.48125 0.848437 2.99375 1.04531 2.5375C1.24219 2.08125 1.50937 1.68437 1.84687 1.34687C2.18437 1.00937 2.58125 0.742187 3.0375 0.545312C3.49375 0.348437 3.98125 0.25 4.5 0.25C5.01875 0.25 5.50625 0.348437 5.9625 0.545312C6.41875 0.742187 6.81562 1.00937 7.15312 1.34687C7.49062 1.68437 7.75781 2.08125 7.95469 2.5375C8.15156 2.99375 8.25 3.48125 8.25 4C8.25 4.51875 8.15156 5.00625 7.95469 5.4625C7.75781 5.91875 7.49062 6.31562 7.15312 6.65312C6.81562 6.99062 6.41875 7.25781 5.9625 7.45469C5.50625 7.65156 5.01875 7.75 4.5 7.75ZM4.5 7C5.3375 7 6.04687 6.70937 6.62812 6.12812C7.20937 5.54687 7.5 4.8375 7.5 4C7.5 3.1625 7.20937 2.45312 6.62812 1.87187C6.04687 1.29062 5.3375 1 4.5 1C3.6625 1 2.95312 1.29062 2.37187 1.87187C1.79062 2.45312 1.5 3.1625 1.5 4C1.5 4.8375 1.79062 5.54687 2.37187 6.12812C2.95312 6.70937 3.6625 7 4.5 7Z" fill="white"/>
            </svg>
            <span>
              {rating}
            </span>
          </div>
        </div>

        <ul className={cls.list}>
          <li className={cls.item}>
            <div className={cls.itemNameBlock}>
              <div className={cls.iconBlock}>
                <ForehandIcon />
              </div>
              <h3 className={cls.itemName}>
                FOREHAND
              </h3>
            </div>
            <LinearProgress color={progressColor} styles={{height: '30px', borderRadius: '20px'}} progress={chr_forehand} />
          </li>
          <li className={cls.item}>
            <div className={cls.itemNameBlock}>
              <div className={cls.iconBlock}>
                <ServeIcon />
              </div>
              <h3 className={cls.itemName}>
                SERVE
              </h3>
            </div>
            <LinearProgress color={progressColor} styles={{height: '30px', borderRadius: '20px'}} progress={chr_serve} />
          </li>
          <li className={cls.item}>
            <div className={cls.itemNameBlock}>
              <div className={cls.iconBlock}>
                <StaminaIcon />
              </div>
              <h3 className={cls.itemName}>
                STAMINA
              </h3>
            </div>
            <LinearProgress color={progressColor} styles={{height: '30px', borderRadius: '20px'}} progress={chr_stamina} />
          </li>
          <li className={cls.item}>
            <div className={cls.itemNameBlock}>
              <div className={cls.iconBlock}>
                <ReceptionIcon />
              </div>
              <h3 className={cls.itemName}>
                RECEPTION
              </h3>
            </div>
            <LinearProgress color={progressColor} styles={{height: '30px', borderRadius: '20px'}} progress={chr_reception} />
          </li>
          <li className={cls.item}>
            <div className={cls.itemNameBlock}>
              <div className={cls.iconBlock}>
                <VolleyIcon />
              </div>
              <h3 className={cls.itemName}>
                VOLLEY
              </h3>
            </div>
            <LinearProgress color={progressColor} styles={{height: '30px', borderRadius: '20px'}} progress={chr_volley} />
          </li>
          <li className={cls.item}>
            <div className={cls.itemNameBlock}>
              <div className={cls.iconBlock}>
                <BackHandIcon />
              </div>
              <h3 className={cls.itemName}>
                BACKHAND
              </h3>
            </div>
            <LinearProgress color={progressColor} styles={{height: '30px', borderRadius: '20px'}} progress={chr_backhand} />
          </li>
          <li className={cls.item}>
            <div className={cls.itemNameBlock}>
              <div className={cls.iconBlock}>
                <SpiritIcon />
              </div>
              <h3 className={cls.itemName}>
                SPIRIT
              </h3>
            </div>
            <LinearProgress color={progressColor} styles={{height: '30px', borderRadius: '20px'}} progress={chr_spirit} />
          </li>
        </ul>
        <footer className={cls.footer}>
          <div>
            <h3 className={cls.tire}>TIRE <span>{chr_fatigue}%</span></h3>
          </div>
        </footer>
      </div>
    </div>
  )
})