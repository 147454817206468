import MainIcon from '@assets/icons/sidebar/main.svg';
import ShopIcon from '@assets/icons/sidebar/shop.svg';
import PlayIcon from '@assets/icons/sidebar/play.svg';
import RatingIcon from '@assets/icons/sidebar/rating.svg';

export interface SidebarItemType {
  Icon: string;
  name: string;
  to: string;
}

export const sidebarItems: SidebarItemType[] = [
  {
    name: 'Main',
    Icon: MainIcon,
    to: '/',
  },
  {
    name: 'Shop',
    Icon: ShopIcon,
    to: '/market/cards',
  },
  {
    name: 'Play',
    Icon: PlayIcon,
    to: '/tournaments',
  },
  {
    name: 'Rating',
    Icon: RatingIcon,
    to: '/rating/users',
  },
];