import { memo } from "react";
import LogoIcon from '@assets/icons/logo.svg';
import classNames from "classnames";
import cls from './Sidebar.module.scss';
import { sidebarItems } from "../model/sidebar";
import { SidebarItem } from "./SidebarItem/SidebarItem";
import { Link } from "react-router-dom";

interface SidebarProps {
  className?: string;
}

export const Sidebar = memo((props: SidebarProps) => {
  const { className } = props;
  return (
    <aside className={classNames(cls.Sidebar, className)}>
      <Link to="/">
        <LogoIcon className={cls.logo} />
      </Link>
      <nav className={cls.nav}>
        <ul className={cls.navList}>
          {sidebarItems.map(item => (
            <li key={item.name + '_' + item.to} className={cls.item}>
              <SidebarItem item={item}/>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  )
})