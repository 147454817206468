import { ButtonHTMLAttributes, memo } from "react";
import classNames from "classnames";
import cls from './Button.module.scss';

type ButtonThemes = 'contained-purple' | 'outlined-white' | 'contained-green' | 'contained-white'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: ButtonThemes;
}

export const Button = memo((props: ButtonProps) => {
  const { children, theme = 'contained-purple', className } = props;
  return (
    <button className={classNames(cls.Button, cls[theme], className)}>
      {children}
    </button>
  )
})