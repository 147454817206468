import { memo } from "react";
import classNames from "classnames";
import cls from './HeaderProfileCard.module.scss';
import AuthService from "@services/AuthService";
import { BACKEND_BASE_URL } from "@constants/constants";
import { Avatar } from "../../../../newComponents/shared/Avatar/Avatar";
import { Link } from "react-router-dom";

interface HeaderProfileCardProps {
  className?: string;
}

export const HeaderProfileCard = memo((props: HeaderProfileCardProps) => {
  const { className } = props;
  const user = AuthService.getCurrentUser();

  console.log(user)

  return (
    <Link className={classNames(cls.HeaderProfileCard, className)} to={`/profile/${user.id}`}>
      <h3 className={cls.name}>
        {user.nickname}
      </h3>
      <Avatar src={BACKEND_BASE_URL + user.avatar} alt="" />
    </Link>
  )
})