import { memo } from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import cls from './SidebarItem.module.scss';
import type { SidebarItemType } from '../../model/sidebar';

interface SidebarItemProps {
  item: SidebarItemType;
  className?: string;
}

export const SidebarItem = memo((props: SidebarItemProps) => {
  const { item, className } = props;
  const {pathname} = useLocation();

  return (
    <Link
      className={classNames(cls.SidebarItem, item.to === pathname && cls.active, className)}
      to={item.to}
    >
      <item.Icon className={cls.icon} />
      <h3 className={cls.name}>
        {item.name}
      </h3>
    </Link>
  )
})