import { Suspense, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Route, Routes, useLocation,
  useSearchParams,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PageLoader from '@components/PageLoader';
import ProfileLayout from '@screens/Profile/ProfileLayout';
import tennisBg from '@img/tennis-bg.png';
import NotFoundPage from '@screens/NotFoundPage';
import { Tournament } from './models/Tournament';
import { AuthContextProvider } from './context/AuthContext';
import { NotificationContextProvider } from './context/NotificationContext';
import Login from './screens/Authentication/Login';
import { PopupProvider } from './providers/PopupProvider';
import ProtectedRoute from './routes/ProtectedRoute';
import ProtectedRouteElementForAuthorized from './routes/ProtectedRouteForAuthorized';
import ProtectedRouteForStudy from './routes/ProtectedRouteForStudy';
import MyTeams from './screens/Profile/MyTeams';
import RegistrationFirstStep from './screens/Authentication/RegistrationFirstStep';
import CodeConfirmation from './screens/Authentication/CodeConfirtmation';
import Footer from './screens/Footer';
// import { Header } from '@layout/Header';
import Header from '@screens/Header';
import RegistrationTwoStep from './screens/Authentication/RegistrationTwoStep';
import GetNFTFromMysteryBox from './screens/Marketplace/GetNFTFromMysteryBox';
import Composition from './screens/Composition/Composition';
import MyAchievments from './screens/Profile/MyAchievments';
import MyCards from './screens/Profile/MyCards';
import Grid_Tournament from './screens/Tournament/Grid_Tournament';
import Main_Tournament from './screens/Tournament/Main_Tournament';
import Card_Prize from './screens/Tournament/Card_Prize';
import MainPage from './screens/MainPage';
import {
  ROUTE_ACHIEVEMENTS,
  ROUTE_CARD,
  ROUTE_CARDS,
  ROUTE_CARDS_DROP,
  ROUTE_CASE_PAGE,
  ROUTE_CODE_CONFIRMATION,
  ROUTE_CONTACTS_PAGE,
  ROUTE_CONTROL_COMMAND,
  ROUTE_GAME_RULES,
  ROUTE_GET_FIRST_CARDS,
  ROUTE_INVITE_PAGE,
  ROUTE_LOGIN,
  ROUTE_MAIN,
  ROUTE_MARKETPLACE,
  ROUTE_MY_TEAMS,
  ROUTE_MYCOMPOSITION,
  ROUTE_MYSTERY_CASE_PAGE,
  ROUTE_PARTNER,
  ROUTE_PARTNER_INFO,
  ROUTE_POLICY_PAGE,
  ROUTE_PROFILE,
  ROUTE_RATING,
  ROUTE_REGISTRATION_TWO,
  ROUTE_RESET_PASSWORD,
  ROUTE_SENDCODE,
  ROUTE_TERMS_PAGE,
  ROUTE_TOURNAMENT,
  ROUTE_TOURNAMENT_CALENDAR,
  ROUTE_TRAINING,
} from './constants/constants';
import Rating from './screens/Rating/Rating';
import PartnerForm from './screens/Partner/PartnerForm';
import ResetPassword from './screens/Authentication/ResetPassword';
import Training from './screens/Training';
import MarketplaceMain from './screens/Marketplace/MarketplaceMain';
import GetRegistrationCard from './screens/Authentication/GetRegistrationCard';
import SellCard from './screens/Marketplace/SellCard';
import GoSellPage from './screens/Marketplace/Pages/GoSellPage';
import CalendarTournament from './screens/Tournament/CalendarTournament';
import PartnerInfo from './screens/Partner/PartnerInfo';
import MyCard from './screens/Profile/MyCard';
import Upgrade from './screens/Upgrade';
import ControlCommand from './screens/ControlCommand';
import LearnReplaceCardPage from './screens/Learning/LearnReplaceCardPage';
import './vendor';
import GameRulesPage from '@screens/GameRulesPage';
import TermsPage from '@screens/TermsPage';
import Policy from '@screens/Policy';
import ContactsPage from '@screens/ContactsPage';
import InvitePage from '@screens/Referal';
import CardsDropPage from '@screens/CardsDropPage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import CasePage from '@screens/CasePage';
import FlappyBirdGame from '@screens/FlappyBirdGame/ui';
import { DinoGame } from '@screens/DinoGame';
import { PingPongGame } from '@screens/PingPongGame';
import MyPlayers from '@screens/Profile/MyPlayers';
import MysteryCasePage from '@screens/MysteryCasePage';
import { GameOverlay } from '@components/GameOverlay/GameOverlay';
import TetrisGame from '@components/TetrisGame';

import bg1Image from '@img/bg-1.jpg'
import bg2Image from '@img/bg-2.jpg'
import bg3Image from '@img/bg-3.jpg'
import bg4Image from '@img/bg-4.jpg'
import { CardPage } from '@screens/CardPage';
import { SidebarItem } from '@layout/Sidebar/ui/SidebarItem/SidebarItem';
import { Sidebar } from '@layout/Sidebar';
import { Layout } from '@layout/Layout';
import { Main } from './newComponents/screens/Main';

const getRandomBackroundImage = () => {
  const randomNum = Math.floor(Math.random() * 4) + 1;
  switch (randomNum) {
    case 1:
      return bg1Image
    case 2:
      return bg2Image
    case 3:
      return bg3Image
      case 4:
        return bg4Image
  
    default:
      break;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  axios.interceptors.request.use((config) => {
    config.headers['Accept-Language'] = localStorage.getItem('i18nextLng');
    return config;
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [idTournament, setIdTournament] = useState<number | null>(null);
  const [tournamentCard, setTournamentCard] = useState<Tournament>();
  const [friendlyInviteUserId, setFriendlyInviteUserId] = useState<
    number | null
  >(null);
  const [isRegisterTournament, setIsRegisterTournament] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    console.log('idTournament', idTournament);
  }, [idTournament]);

  useEffect(() => {
    const referalId = searchParams.get('ref');
    if (referalId && typeof localStorage !== 'undefined' && !localStorage.getItem('user')) {
      localStorage.setItem('refId', referalId);
    }
  }, []);

  return (
    <div
      className={`${location.pathname !== '/dino' || location.pathname === '/tennis' ? 'min-h-[100vh]' : 'max-h-[100vh]'} relative bg-black bg-no-repeat bg-cover bg-fixed font-century text-white`}
      style={{
         animation: 'grain 6s steps(10) infinite',
         backgroundImage: `url(${location.pathname === '/' ? undefined : getRandomBackroundImage()})`,
         backgroundSize: 'cover',
      }}
    >
      <Layout>
        <QueryClientProvider client={queryClient}>
          <NotificationContextProvider>
            <PopupProvider>
              <AuthContextProvider>
                <div style={{ minHeight: location.pathname !== '/dino' && location.pathname !== '/tennis' && '100vh' }}>
                  {/* <button onClick={() => methodDoesNotExist()}>Break the world</button> */}
                  <Suspense fallback={<PageLoader />}>
                    <Routes>
                      <Route path={ROUTE_MAIN} element={<Main />} />
                      <Route path={ROUTE_GAME_RULES} element={<GameRulesPage />} />
                      <Route path={ROUTE_TERMS_PAGE} element={<TermsPage />} />
                      <Route path={ROUTE_POLICY_PAGE} element={<Policy />} />
                      <Route path={ROUTE_INVITE_PAGE} element={<InvitePage />} />
                      <Route path={ROUTE_CONTACTS_PAGE} element={<ContactsPage />} />
                      <Route path={`${ROUTE_RATING}/*`} element={<Rating />} />
                      <Route path={ROUTE_PARTNER} element={<PartnerForm />} />
                      <Route
                        path={ROUTE_PARTNER_INFO}
                        element={<PartnerInfo />}
                      />
                      <Route path={ROUTE_CARDS_DROP} element={<CardsDropPage />} />
                      {/* <Route path={ROUTE_MAIN} element={<MainPage />} /> */}
                      <Route element={<ProtectedRoute />}>
                        <Route
                          path={ROUTE_CASE_PAGE}
                          element={<CasePage />}
                        />
                        <Route
                          path={ROUTE_MYSTERY_CASE_PAGE}
                          element={<MysteryCasePage />}
                        />
                        <Route 
                          path={ROUTE_CARD}
                          element={typeof window === 'undefined' ? null : <CardPage />}
                        />
                        <Route
                          path={`${ROUTE_TRAINING}/*`}
                          element={<Training />}
                        />
                        <Route
                          path={`${ROUTE_CARDS}/:card_id`}
                          element={<MyCard />}
                        />
                        <Route
                          path={ROUTE_MY_TEAMS}
                          element={(
                            <MyTeams
                              idTournament={idTournament}
                              isRegisterTournament={isRegisterTournament}
                              setIsRegisterTournament={setIsRegisterTournament}
                              setIdTournament={setIdTournament}
                              tournamentCard={tournamentCard}
                              friendlyInviteUserId={friendlyInviteUserId}
                            />
                          )}
                        />
                        <Route
                          path={ROUTE_ACHIEVEMENTS}
                          element={<MyAchievments />}
                        />
                        <Route
                          path='/players'
                          element={<MyPlayers />}
                        />
                        <Route path={ROUTE_CARDS} element={<MyCards />} />
                        <Route
                          path={`${ROUTE_MYCOMPOSITION}/:team_id/*`}
                          element={<Composition />}
                        />
                        <Route
                          path={`${ROUTE_PROFILE}/` + '*'}
                          element={(
                            <ProfileLayout
                              setFriendlyInviteUserId={setFriendlyInviteUserId}
                            />
                          )}
                        />
                        <Route
                          path={ROUTE_GET_FIRST_CARDS}
                          element={<GetRegistrationCard />}
                        />
                        <Route
                          path={`${ROUTE_MARKETPLACE}new_sell/`}
                          element={<GoSellPage />}
                        />
                        <Route
                          path={`${ROUTE_MARKETPLACE}new_sell/:card_id/*`}
                          element={<SellCard />}
                        />
                        <Route
                          path={`${ROUTE_MARKETPLACE}*`}
                          element={<MarketplaceMain />}
                        />

                        <Route
                          path={`${ROUTE_TOURNAMENT_CALENDAR}`}
                          element={<CalendarTournament />}
                        />
                        <Route
                          path={`${ROUTE_TOURNAMENT}/:tournament_id/*`}
                          element={(
                            <Grid_Tournament
                              setIdTournament={setIdTournament}
                              tournament={tournamentCard}
                            />
                          )}
                        />
                        <Route
                          path={`${ROUTE_TOURNAMENT}/:tournament_id/:round_id/*`}
                          element={
                            <Card_Prize setIdTournament={setIdTournament} />
                          }
                        />
                        <Route
                          path={ROUTE_TOURNAMENT}
                          element={(
                            <Main_Tournament
                              setIsRegisterTournament={setIsRegisterTournament}
                              setTournamentCard={setTournamentCard}
                              setIdTournament={setIdTournament}
                            />
                          )}
                        />
                        <Route path="/game_flappy" element={<GameOverlay name="Игра Tennis Bird" notRotateForMobile>
                          <FlappyBirdGame />
                        </GameOverlay>} />
                        <Route path="/tetris" element={<GameOverlay closeWithReload name="Игра Tetris" notRotateForMobile>
                          <TetrisGame />
                        </GameOverlay>} />
                        <Route path="/tennis" element={<GameOverlay name="Игра Ping Pong">
                          <PingPongGame />
                        </GameOverlay>} />
                        <Route path="upgrade/*" element={<Upgrade />} />
                        <Route element={<ProtectedRouteForStudy />}>
                          {/* {user?.is_learned && */}
                          <Route
                            path={`${ROUTE_TOURNAMENT}/0`}
                            element={<LearnReplaceCardPage />}
                          />
                        </Route>
                        <Route
                          path={`${ROUTE_CONTROL_COMMAND}/*`}
                          element={<ControlCommand />}
                        />
                      </Route>
                      <Route element={<ProtectedRouteElementForAuthorized />}>
                        <Route
                          path={ROUTE_SENDCODE}
                          element={<RegistrationFirstStep />}
                        />
                        <Route path={ROUTE_LOGIN} element={<Login />} />
                        <Route
                          path={ROUTE_CODE_CONFIRMATION}
                          element={<CodeConfirmation />}
                        />
                        <Route
                          path={ROUTE_REGISTRATION_TWO}
                          element={<RegistrationTwoStep />}
                        />
                        <Route
                          path={ROUTE_RESET_PASSWORD}
                          element={<ResetPassword />}
                        />
                      </Route>
                      <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                  </Suspense>
                </div>
                {(location.pathname !== '/dino' && location.pathname !== '/tennis')  && <Footer />}
              </AuthContextProvider>
            </PopupProvider>
          </NotificationContextProvider>
        </QueryClientProvider>
      </Layout>
      <ToastContainer />
    </div>
  );
};
