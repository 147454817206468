import { memo } from "react";
import { headerAuthItems } from "../../model/header";
import classNames from "classnames";
import cls from './HeaderAuthContent.module.scss';
import { HeaderProfileCard } from "../HeaderProfileCard/HeaderProfileCard";
import { Link } from "react-router-dom";

interface HeaderAuthContent {
  className?: string;
}

export const HeaderAuthContent = memo((props: HeaderAuthContent) => {
  const { className } = props;

  return (
    <div className={classNames(cls.HeaderAuthContent, className)}>
      <h2 className={cls.title}>Page</h2>
      <div className={cls.control}>
        <ul className={cls.links}>
          {headerAuthItems.map((item, index) => (
            <li className={classNames(cls.link, index === 1 && cls.withMargin)}>
              <Link to={item.to}>
                <item.Icon />
              </Link>
            </li>
          ))}
        </ul>
        <HeaderProfileCard />
      </div>
    </div>
  )
})