import SearchIcon from '@assets/icons/search.svg';
import BalanceIcon from '@assets/icons/balance.svg';
import NotificationsIcon from '@assets/icons/notifications.svg';

interface HeaderAuthItem {
  to: string;
  Icon: string;
}

export const headerAuthItems: HeaderAuthItem[] = [
  {
    to: '/',
    Icon: SearchIcon
  },
  {
    to: '/profile/balance',
    Icon: BalanceIcon
  },
  {
    to: '/profile/notifications',
    Icon: NotificationsIcon
  },
]