import { ReactNode } from "react";
import classNames from "classnames";
import cls from './Container.module.scss';
import AuthService from "@services/AuthService";

interface ContainerProps {
  children: ReactNode;
  className?: string;
}

export const Container = (props: ContainerProps) => {
  const { children, className } = props;
  const user = AuthService.getCurrentUser();

  return (
    <div className={classNames(cls.Container, user && cls.withSidebar,className)}>
      {children}
    </div>
  )
}