import { Box, Html, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import "./CardPage.scss";
import { useLocation, useParams } from "react-router-dom";
import { BackCard, NewCharacteristicCard } from "@components/NewCharacteristicCard";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import domtoimage from 'dom-to-image';
import axios from "axios";
import { BACKEND_URL } from "@constants/constants";
import { AuthContext } from "@context/AuthContext";
import { PlayerCard } from "@components/PlayerCard";
import newPlayerCardFrontImage from './front.png';
import newPlayerCardBackImage from './back.png';

const getCardColor = (rarity: string) => {
  console.log('rarity', rarity)
  switch(rarity) {
    case "ADVANCED":
      return 'rgb(229,178,77)'
    case "UNIQUE":
      return 'rgb(229,178,77)'
    case "LEGENDARY":
      return 'rgb(229,178,77)'
    case "RARE": 
      return 'rgb(229,178,77)'
    case "COMMON":
      return 'rgb(229,178,77)'
  }
}

const Card = ({ frontTexture, backTexture, color }) => {
  return (
    <Box args={[3.8, 6, 0.25]} position={[0, 0.2, 0]} receiveShadow>
    <meshStandardMaterial color={color} roughness={0.2} metalness={0.8} emissive={color} />
    <mesh position={[0, 0, 0.13]} receiveShadow castShadow>
      <planeGeometry args={[3.8, 6]} />
      <meshStandardMaterial map={frontTexture} roughness={0.1} metalness={0.8} />
    </mesh>
    <mesh position={[0, 0, -0.13]} rotation={[0, Math.PI, 0]} receiveShadow castShadow>
      <planeGeometry args={[3.8, 6]} />
      <meshStandardMaterial map={backTexture} roughness={0.1} metalness={0.8} />
    </mesh>

      <Html position={[0, 0, 0.1]} transform style={{ position: 'absolute', top: '-105px', left: '-55px' }}>
        {/* Ваше содержимое HTML здесь */}
      </Html>
    </Box>
  );
};

export const CardPage = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const { id } = useParams();

  const captureRef = useRef(null);
  const secondCaptureRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [secondImageSrc, setSecondImageSrc] = useState(null);
  const [card, setCard] = useState();

  const captureImages = async () => {
    const results = await Promise.allSettled([
        domtoimage.toPng(captureRef.current),
        domtoimage.toPng(secondCaptureRef.current)
    ]);

    results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
            index === 0 ? setImageSrc(result.value) : setSecondImageSrc(result.value);
        } else {
            console.error('oops, something went wrong!', result.reason);
        }
    });
  };

  async function fetchCard() {
    try {
      const { data } = await axios.get(`${BACKEND_URL}/nft/nft_card/${id}/`, {
        headers: {
          Authorization: `Bearer ${user!.token}`
        }
      })
      setCard(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!card) {
      fetchCard();
    }
    if (card) {
      const timer = setTimeout(() => {
        captureImages();
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [card]);

  const cardColor = useMemo(() => getCardColor(card?.nft.rarity), [card])

  return (
    <div style={location.pathname.includes('/profile/') ? { width: '300px', height: '400px' } : { height: '55vh' }}>
      {!imageSrc && card && <div style={{ position: "fixed", top: 1000 }}>
        <div
          ref={captureRef}
          // style={{ padding: "20px", border: "1px solid black" }}
        >
          <NewCharacteristicCard card={card} width={540} height={876} />
        </div>
      </div>}
      {!secondImageSrc && card && <div style={{ position: "fixed", top: 1000 }}>
        <div
          ref={secondCaptureRef}
          // style={{ padding: "20px", border: "1px solid black" }}
        >
          <BackCard card={card} />
        </div>
      </div>}
      {imageSrc && secondImageSrc && card && cardColor && <Canvas shadows style={{overflow: 'visible'}}>
      <ambientLight intensity={13} />
      <directionalLight position={[10, 10, 10]} intensity={2} castShadow />
      <pointLight position={[0, 0, -5]} intensity={2} castShadow />
      <spotLight position={[5, 5, 5]} intensity={1.5} angle={0.2} penumbra={1} castShadow />
        <Card color={cardColor} frontTexture={useLoader(TextureLoader, newPlayerCardFrontImage)} backTexture={useLoader(TextureLoader, newPlayerCardBackImage)} />
        {location.pathname.includes('/profile/') ? <OrbitControls minDistance={6} maxDistance={6} /> : <OrbitControls minDistance={4} maxDistance={5} />}
      </Canvas>}
    </div>
  );
};

