import { memo } from 'react';
import './LinearProgress.scss';

const LinearProgress = memo((props: any) => {
  const color = props.color === 'text-white' ? 'white' : props.color === 'text-black' ? 'black' : props.color

  const { progress = 0, styles } = props;
  return (
    <div className="linear-progress" style={{ border: `0.5px solid ${color}`, ...styles }}>
      <div className="linear-percent" style={{ width: `${progress}%`, background: color, borderRadius: styles?.borderRadius }} />
    </div>
  );
});

export default LinearProgress;
