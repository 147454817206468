import { memo } from "react";
import SecondLogoIcon from '@assets/icons/second-logo.svg';
import classNames from 'classnames';
import cls from './Header.module.scss'
import AuthService from "@services/AuthService";
import { HeaderNotAuthContent } from "./HeaderNotAuthContent/HeaderNotAuthContent";
import { HeaderAuthContent } from "./HeaderAuthContent/HeaderAuthContent";

interface HeaderProps {
  className?: string;
}

export const Header = memo((props: HeaderProps) => {
  const { className } = props;
  const user = AuthService.getCurrentUser();
  return (
    <header className={classNames(cls.Header, className)}>
      <div className={cls.content}>
        {user ? <HeaderAuthContent /> : <HeaderNotAuthContent />}
      </div>
    </header>
  )
})