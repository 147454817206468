import { ImgHTMLAttributes, memo } from "react";
import classNames from 'classnames';
import cls from './Avatar.module.scss';

type AvatarSizes = 'small' | 'large';

interface AvatarProps extends ImgHTMLAttributes<HTMLImageElement> {
  size?: AvatarSizes;
}

export const Avatar = memo((props: AvatarProps) => {
  const { size = 'small', alt, src, className } = props;

  return (
    <img
      className={classNames(cls.Avatar, cls[size], className)}
      alt={alt}
      src={src}
    />
  )
})