import { FC, memo } from 'react';
import usePopup from '@hooks/usePopup';
import type { TPopupBox } from './types';

const PopupBox: FC<TPopupBox> = memo((props) => {
  const {
    id, children, buttons, contentBlockClassName, navigate, route, className, onClose, notBublingBg,
  } = props;
  const { closePopup, openPopups } = usePopup();
  const isOpen = openPopups.includes(id);

  return (
    <>
      <div
        className={`fixed w-screen h-screen px-2 top-0 left-0 z-[100] justify-center items-center overflow-x-hidden ${
          isOpen ? 'flex' : 'invisible'
        } ${className}`}
      >
        <div
          className="w-screen h-screen fixed top-0 left-0 bottom-0 right-0 bg-[rgba(0,0,0,.4)] backdrop-blur"
          onClick={notBublingBg ? undefined : () => {
            onClose?.();
            closePopup(id);
            if (navigate) {
              navigate(`${route}`, { replace: true });
            }
          }}
        />
        <div className={`relative min-w-[300px] z-[90]${contentBlockClassName ? ` ${contentBlockClassName}` : ''}`}>
          <div className="p-4 xl:px-12 xl:py-6 rounded-xl" style={{ backgroundColor: 'rgb(82 82 82 / 0.3)' }}>
{!notBublingBg &&             (<div
              className="absolute text-white cursor-pointer top-4 right-4"
              onClick={() => {
                onClose?.();
                closePopup(id);
                if (navigate) {
                  navigate(`${route}`, { replace: true });
                }
              }}
            >
              X
            </div>)}
            <div>
              {children}
            </div>
          </div>
          <div className="mt-4">
            {buttons || (
              <button
                className="button button-white"
                onClick={() => {
                  closePopup(id);
                }}
              >
                ОК
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default PopupBox;
