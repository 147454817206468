import { ReactNode } from "react"
import { Container } from "./Container"
import { Header } from "./Header"
import { Sidebar } from "./Sidebar"
import AuthService from "@services/AuthService";

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const user = AuthService.getCurrentUser();
  return (
    <>
      <Container>
        {user && <Sidebar />}
        <Header />
      </Container>
      {children}
    </>
  )
}